/*
 * @Author: lazyzzd
 * @Date: 2022-06-28 21:10:19
 * @LastEditors: lazyzzd
 * @LastEditTime: 2022-07-06 21:32:25
 * @Description: 订单接口
 */
import { IOrder, IPagerReturn, IPagerParams, IResponse } from "@/interface";
import request from "../utils/request";

/**
 * 获取订单列表
 * @returns 订单列表
 */
export const getOrders = (
  params: IPagerParams & IOrder
): Promise<IPagerReturn<IOrder>> => request.post("/api/order/list", params);

/**
 * 新增订单
 * @param data 订单信息
 * @returns IResponse
 */
export const addOrder = (data: IOrder): Promise<IResponse<undefined>> =>
  request.post("/api/order/add", data);

/**
 * 更新订单信息
 * @param _id 订单的id
 * @param data 订单信息
 * @returns IResponse
 */
export const updateOrder = (
  _id: string,
  data: IOrder
): Promise<IResponse<undefined>> =>
  request.post("/api/order/update", { _id, data });

/**
 * 取消订单标记
 * @param _id 需要取消的订单id，可不传
 * @returns IResponse
 */
export const cancelMark = (_id?: string): Promise<IResponse<undefined>> =>
  request.post("/api/order/cancelMark", { _id });
