import { IOrder } from "@/interface";
import { IOption } from "@/components/CustomTable/interface";
import dayjs from "dayjs";

// 表格配置
export const orderOptions: IOption<IOrder>[] = [
  {
    label: "图片",
    slot: "thumb",
    width: 80,
  },
  {
    label: "购买订单号",
    prop: "orderId",
    width: 80,
    style: {
      color: "#409EFC",
      cursor: "pointer",
    },
    click({ orderId, storeWebsite }: IOrder): void {
      window.open(`${storeWebsite}/admin/orders/${orderId}`);
    },
  },
  {
    label: "店铺名称",
    prop: "storeName",
    width: 150,
  },
  {
    label: "购买日期",
    prop({ purchaseDate }: IOrder): string {
      return purchaseDate ? dayjs(purchaseDate).format("YYYY-MM-DD") : "--";
    },
  },
  {
    label: "订单状态",
    prop: "status",
  },
  {
    label: "购买价格",
    prop: "purchasePrice",
  },
  {
    label: "阿里状态",
    prop: "aliStatus",
    width: 110,
  },
  {
    label: "自定义状态",
    prop: (item: IOrder): string => {
      const statusCode = item.customStatus;
      if (!statusCode) {
        return "--";
      }
      return customStatus.find((e) => e.value === statusCode)?.label || "--";
    },
    fixed: "right",
    width: 100,
  },
  {
    label: "标记",
    prop: (item: IOrder): string => {
      const newType = item.newType;
      if (!newType) {
        return "--";
      }
      return newTypes.find((e) => e.value === newType)?.label || "--";
    },
    fixed: "right",
  },
  {
    label: "备注",
    prop: "remark",
    fixed: "right",
  },
  {
    label: "操作",
    slot: "operate",
    fixed: "right",
  },
];

// 展开列配置
export const expandOptions: IOption<IOrder>[] = [
  {
    label: "购买人",
    prop: "buyer",
  },
  {
    label: "国家",
    prop: "country",
  },
  {
    label: "地址",
    prop: "address",
  },
  {
    label: "邮编",
    prop: "zipCode",
  },
  {
    label: "电话",
    prop: "phone",
  },
  {
    label: "运单号",
    prop: "waybill",
  },
  {
    label: "阿里购买时间",
    prop({ aliDate }: IOrder): string {
      return aliDate ? dayjs(aliDate).format("YYYY-MM-DD HH:mm") : "--";
    },
    width: 140,
  },
  {
    label: "阿里订单号",
    prop: "aliOrderId",
    width: 120,
  },
  {
    label: "阿里价格",
    prop: "aliPrice",
  },
];

export const customStatus: { label: string; value: number }[] = [
  {
    label: "无货",
    value: 1,
  },
  {
    label: "待处理",
    value: 2,
  },
  {
    label: "已发货",
    value: 3,
  },
  {
    label: "已退款",
    value: 4,
  },
  {
    label: "其他",
    value: 5,
  },
];

export const newTypes: { label: string; value: number }[] = [
  {
    label: "最新下单",
    value: 1,
  },
  {
    label: "已购买",
    value: 2,
  },
  {
    label: "已发货",
    value: 3,
  },
  {
    label: "阿里状态更新",
    value: 4,
  },
  {
    label: "store状态更新",
    value: 5,
  },
  {
    label: "订单异常",
    value: 6,
  },
];
