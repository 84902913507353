/*
 * @Description: 分页自定义hooks
 * @Author: zhuangli5
 * @Date: 2022-06-16 18:01:36
 */
import { reactive } from "vue";
import { debounce } from "lodash";
import type { IFetch, IUsePagerReturn } from "./interface";

const usePager = (fetch: IFetch, { pageSize = 20 } = {}): IUsePagerReturn => {
  const data = reactive({
    pageIndex: 1,
    pageSize: pageSize,
    total: 0,
  });

  // 刷新，增加防抖处理
  const refresh = debounce(async () => {
    const { pageIndex, pageSize } = data;
    const { total, pageIndex: newPageIndex } = await fetch({
      pageIndex,
      pageSize,
    });
    data.total = total;
    newPageIndex && (data.pageIndex = newPageIndex);
  }, 50);

  const handleSizeChange = (newPagesize: number) => {
    data.pageIndex = 1;
    data.pageSize = newPagesize || pageSize;
    refresh();
  };

  const handleCurrentChange = (pageIndex: number) => {
    data.pageIndex = pageIndex;
    refresh();
  };

  const reset = (pageSize?: number) => {
    handleSizeChange(pageSize || data.pageSize);
  };

  refresh();

  return [data, { handleSizeChange, handleCurrentChange, reset }];
};

export default usePager;
